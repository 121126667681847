import FaceIcon from "@mui/icons-material/Face";
import RobotIcon from "@mui/icons-material/SmartToy";
import { Avatar, Box, Paper, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import { regularTheme } from "../../theme/theme";
import { marked } from "marked";

export const ReviewStep = ({ questionsAndResponses }) => {
  const theme = regularTheme;
  const [selectedTab, setSelectedTab] = useState(0); // Track selected tab
  const tabLabels = ["Beginner", "Intermediate", "Advanced"];
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const processContentForDisplay = (content) => {
    if (!content) {
      return "";
    }

    const latexMatches = [];
    let placeholderCounter = 0;

    // Replace LaTeX with placeholders
    let modifiedText = content.replace(
      /\\\[(.*?)\\\]|\\\((.*?)\\\)/gs,
      (match) => {
        const placeholder = `{{LATEX_PLACEHOLDER_${placeholderCounter++}}}`;
        latexMatches.push(match); // Store the original LaTeX
        return placeholder; // Replace LaTeX with a placeholder
      }
    );

    // Assuming `marked` is available and `content` is your input Markdown with placeholders
    let html = marked(modifiedText);

    // Replace each placeholder with its corresponding LaTeX
    latexMatches.forEach((latex, index) => {
      const placeholder = `{{LATEX_PLACEHOLDER_${index}}}`;
      html = html.replace(placeholder, latex);
    });

    // Return processed content as HTML string
    return html;
  };

  return (
    <Box sx={{ height: "100%", overflowY: "hidden" }}>
      {/* Tabs for each question */}
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        {tabLabels.map((label, index) => (
          <Tab label={label} key={index} />
        ))}
      </Tabs>

      {/* Display content based on selected tab */}
      {questionsAndResponses.map((qa, index) => (
        <Box
          key={index}
          role="tabpanel"
          hidden={selectedTab !== index}
          sx={{ height: "100%", p: 2 }}
        >
          {selectedTab === index && (
            <>
              {/* Box to hold messages, with scrollable behavior */}
              <Box
                sx={{
                  maxHeight: "70%", // Set a fixed height for the scrollable area
                  overflowY: "auto", // Make this box scrollable
                  mb: 2,
                }}
              >
                {/* User's question */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mb: 2,
                    p: 1,
                  }}
                >
                  <Paper
                    sx={{
                      padding: "10px 20px",
                      borderRadius: "20px",
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      maxWidth: "80%",
                      boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                    }}
                  >
                    <Avatar
                      style={{ backgroundColor: theme.palette.primary.main }}
                    >
                      <FaceIcon />
                    </Avatar>
                    <Typography variant="body1">{qa.question}</Typography>
                  </Paper>
                </Box>

                {/* Assistant's response with processed content */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    p: 1,
                  }}
                >
                  <Paper
                    sx={{
                      padding: "10px 20px",
                      borderRadius: "20px",
                      backgroundColor: theme.palette.background.paper,
                      color: theme.palette.text.primary,
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      maxWidth: "80%",
                      boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                    }}
                  >
                    <Avatar
                      style={{ backgroundColor: theme.palette.secondary.main }}
                    >
                      <RobotIcon />
                    </Avatar>
                    <Box
                      sx={{ ml: 1 }}
                      dangerouslySetInnerHTML={{
                        __html: processContentForDisplay(qa.response),
                      }}
                    />
                  </Paper>
                </Box>
              </Box>
            </>
          )}
        </Box>
      ))}
    </Box>
  );
};
