import { useMutation } from "react-query";

/**
 * This hook allows completing the onboarding process for a user.
 *
 * @param {String} userId the ID of the user for whom the onboarding process is completed
 * @returns A success message if the onboarding is completed successfully, an error if unsuccessful.
 */

const completeOnboarding = async (userId) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_API}/users/completeOnboarding/${userId}`,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      }
    );

    const onboardingResponse = await response.json();

    if (response.status === 200) {
      return onboardingResponse.response.body;
    } else {
      throw new Error("A backend server error occurred");
    }
  } catch (error) {
    console.error("Error completing onboarding: ", error);
    throw error;
  }
};

export const useCompleteOnboarding = (onSuccessCallback) => {
  const mutation = useMutation((userId) => completeOnboarding(userId), {
    onSuccess: (data) => {
      if (onSuccessCallback) {
        onSuccessCallback(data);
      }
    },
    onError: (error) => {
      console.error(error);
      alert(error.message);
    },
  });
  return mutation;
};
