import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";

// File validation function
const isValidFileType = (file) => {
  const validTypes = [
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "text/plain",
  ];
  return validTypes.includes(file.type);
};

export const UploadMaterialsStep = ({ uploadedFiles, setUploadedFiles }) => {
  const [errorMessage, setErrorMessage] = useState("");

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "text/plain": [".txt"],
    },
    maxFiles: 1, // Allow only one file
    onDrop: (acceptedFiles, rejectedFiles) => {
      const validFiles = acceptedFiles.filter(isValidFileType);
      const invalidFiles = rejectedFiles.map((file) => file.file.name);

      if (invalidFiles.length > 0) {
        setErrorMessage(
          `Invalid file type(s): ${invalidFiles.join(
            ", "
          )}. Only PDF, DOCX, or TXT files are allowed.`
        );
      } else {
        setErrorMessage("");
      }

      // Replace the previously uploaded file with the new one
      if (validFiles.length > 0) {
        setUploadedFiles(validFiles); // Set to only the new file
      }
    },
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "80%",
        textAlign: "center",
        p: 3,
      }}
    >
      <Typography
        variant="h4"
        color="primary"
        sx={{ mb: 2, fontWeight: "bold" }}
      >
        Upload Your Syllabus
      </Typography>

      <Box
        {...getRootProps()}
        sx={{
          border: "2px dashed #aaa",
          padding: "20px",
          cursor: "pointer",
          bgcolor: isDragActive ? "action.hover" : "background.paper",
          width: "80%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <input {...getInputProps()} />
        <CloudUploadIcon sx={{ fontSize: 42, color: "primary.main" }} />
        <Typography variant="body1">Drag and drop a file here</Typography>

        {/* Error Message for Invalid Files */}
        {errorMessage && (
          <Typography color="error" sx={{ mt: 2 }}>
            {errorMessage}
          </Typography>
        )}

        {/* Uploaded File Display */}
        {uploadedFiles.length > 0 && (
          <Box
            sx={{
              mt: 3,
              width: "80%",
              bgcolor: "grey.200",
              p: 2,
              borderRadius: 1,
            }}
          >
            <List dense>
              {uploadedFiles.map((file, index) => (
                <ListItem key={index} sx={{ py: 0.1 }}>
                  <ListItemText primary={file.name} />
                </ListItem>
              ))}
            </List>
          </Box>
        )}
        <Button variant="outlined" sx={{ mt: 2 }}>
          Browse File
        </Button>
      </Box>
      <Typography variant="body2" sx={{ mt: 2 }}>
        Only PDF, DOCX, or TXT files are allowed.
      </Typography>
      <Typography variant="body2" sx={{ fontSize: "0.75rem", mt: 1 }}>
        Your files belong to you, are not shared with others, and are not used
        to train AI models.
        <br />
        <a
          href="https://tailoredtutor.com/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ mt: 1 }}
        >
          Click here to read our privacy policy.
        </a>
      </Typography>
    </Box>
  );
};
