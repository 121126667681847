// src/components/NavBar/NavBar.js
import AccountCircle from "@mui/icons-material/AccountCircle";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ShieldIcon from "@mui/icons-material/Shield";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  Select,
  Toolbar,
  Typography,
} from "@mui/material";
import { keyframes } from "@mui/system";
import React, { useEffect, useState } from "react";
import logo_transparent from "../../assets/images/logo_transparent.png";
import { useMobile } from "../../contexts/MobileContext";
import { useGetCoursesByUser } from "../../hooks/useGetCoursesByUser";

import { OnboardingModal } from "../CIOnboarding/CIOnboardingModal.js";

import { SubmitFeedbackModal } from "../SubmitFeedbackModal/SubmitFeedbackModal";

const NavBar = ({
  drawerOpen,
  setDrawerOpen,
  anchorEl,
  handleMenu,
  handleClose,
  open,
  setCoursesData,
  setSelectedCourse,
  handleLogout,
  toggleAdmin,
  isAdmin,
  inAdmin,
  handleEnroll,
  user,
  coursesData,
  enrolledDepartments,
}) => {
  const [enrolling, setEnrolling] = useState(false);
  const [newCourseName, setNewCourseName] = useState("");
  const [failure, setFailure] = useState(false);
  const [courseIds, setCourseIds] = useState([]);
  const isMobile = useMobile().isMobile;
  const [CIOnboardingModalOpen, setCIOnboardingModalOpen] = useState(false);

  const handleOpenCIOnboardingModal = () => setCIOnboardingModalOpen(true);
  const handleCloseCIOnboardingModal = () => setCIOnboardingModalOpen(false);

  const handleSelectChange = (event) => {
    setNewCourseName(event.target.value);
  };

  const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

  const { mutate: getCourses, isError, data } = useGetCoursesByUser();

  useEffect(() => {
    if (user) {
      getCourses(user);
      setCIOnboardingModalOpen(user.onboarding);
    }
  }, [user, getCourses]);

  useEffect(() => {
    if (data && user && user.enrolledCourses) {
      const ids = data
        .map((course) => course.courseId)
        .filter((courseId) => !user.enrolledCourses.includes(courseId));

      setCourseIds(ids);
      if (ids.length > 0 && newCourseName === "") {
        setNewCourseName(ids[0]);
      }
    }
  }, [data, user]);

  if (isError) {
    return <div>Error fetching courses</div>;
  }

  const handleEnrollClick = async () => {
    const result = await handleEnroll(newCourseName);
    if (result) {
      setEnrolling(false);
      setFailure(false);
      setNewCourseName(""); // Reset the input field after enrolling
      getCourses(user);
    } else {
      setFailure(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submit action
    if (newCourseName.trim()) {
      handleEnrollClick();
    }
  };

  const finishEnrolling = () => {
    setEnrolling(false);
    setFailure(false);
  };

  return (
    <>
      <MuiAppBar position="static">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => setDrawerOpen(!drawerOpen)}
          >
            {drawerOpen ? (
              <KeyboardArrowLeftIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <Typography
              marginLeft={1}
              variant="h4"
              component="h1"
              sx={{
                //fontWeight: "bold", // Bolder font weight
                fontFamily: "initial",
                fontSize: isMobile ? "1.5rem" : "2.5rem",
              }}
            >
              {isMobile
                ? ""
                : inAdmin
                ? "Tailored Tutor | Admin Mode"
                : "Tailored Tutor"}
            </Typography>
            <img
              src={logo_transparent}
              alt="Tailored Tutor logo"
              style={{
                width: "50px",
                height: "50px",
                marginLeft: isMobile ? "0px" : "10px",
              }}
            />
          </div>
          {isAdmin && (
            <>
              <Button
                color="inherit"
                startIcon={<ShieldIcon style={{ fontSize: "25px" }} />}
                onClick={toggleAdmin}
                sx={{
                  marginLeft: "5px",
                  marginRight: isMobile ? "0px" : "20px",
                }}
              >
                {!isMobile && "Admin"}
              </Button>
              {/* {inAdmin && (
                <Button
                  sx={{
                    marginLeft: "5px",
                    marginRight: isMobile ? "0px" : "20px",
                  }}
                  color="inherit"
                  onClick={handleOpenCIOnboardingModal}
                >
                  Make a tutor
                </Button>
              )} */}
            </>
          )}
          <SubmitFeedbackModal user={user}></SubmitFeedbackModal>
          <OnboardingModal
            open={CIOnboardingModalOpen}
            handleClose={handleCloseCIOnboardingModal}
            user={user}
            setCoursesData={setCoursesData}
            setSelectedCourse={setSelectedCourse}
          ></OnboardingModal>
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            sx={{
              animation:
                coursesData.length === 0 && enrolledDepartments.length > 0
                  ? `${pulse} 1s infinite`
                  : "none",
              "@keyframes pulse": {
                "0%": {
                  transform: "scale(1)",
                  opacity: 1,
                },
                "50%": {
                  transform: "scale(1.1)",
                  opacity: 0.7,
                },
                "100%": {
                  transform: "scale(1)",
                  opacity: 1,
                },
              },
            }}
          >
            <AccountCircle fontSize="large" />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                setEnrolling(true);
                handleClose();
              }}
            >
              Enroll in Course
            </MenuItem>

            <MenuItem onClick={handleLogout}>Log Out</MenuItem>
          </Menu>
        </Toolbar>
        <Dialog open={enrolling} onClose={finishEnrolling}>
          <form onSubmit={handleSubmit}>
            <DialogTitle>Enroll in a New Course</DialogTitle>
            <DialogContent>
              <DialogContentText>
                To enroll in a new course, please enter the course name below.
              </DialogContentText>
              <Select
                labelId="course-select-label"
                id="course-select"
                value={newCourseName}
                label="Course Name"
                onChange={handleSelectChange}
                fullWidth
              >
                {courseIds.map((courseId) => (
                  <MenuItem key={courseId} value={courseId}>
                    {courseId}
                  </MenuItem>
                ))}
              </Select>
              {failure && (
                <DialogContentText color={"red"}>
                  Failed to enroll in course.
                </DialogContentText>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={finishEnrolling}>Cancel</Button>
              <Button type="submit" color="primary">
                Enroll
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </MuiAppBar>
    </>
  );
};

export default NavBar;
