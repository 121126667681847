import { useRef } from "react";
import { useMutation } from "react-query";

/**
 * Sends information to the backend and gets an array of message objects.
 *
 * @param {Object} formData - The files to be sent (course materials and syllabi).
 * @returns {Promise<Object[]>} - Promise resolving to the array of message objects.
 */

//  Add courseId and Assistant type (openAiAssistant)
const createTutor = async (formData) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_API}/assistants/createTailoredTutor`,
      {
        method: "POST",
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error(`Server responded with status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("There was an error sending info:", error);
    throw error;
  }
};

export const useCreateTutor = () => {
  const resolveMutation = useRef(null);

  const mutation = useMutation((files) => createTutor(files), {
    onSettled: (data, error) => {
      if (resolveMutation.current) {
        if (error) {
          resolveMutation.current.reject(error);
        } else {
          resolveMutation.current.resolve(data);
        }
      }
    },
  });

  const sendInfoWithPromise = (info) => {
    return new Promise((resolve, reject) => {
      resolveMutation.current = { resolve, reject };
      mutation.mutate(info);
    });
  };

  return { ...mutation, sendInfo: sendInfoWithPromise };
};
