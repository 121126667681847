import { useMutation } from "react-query";

/** Grabs the enrolled courses be user object
 *
 * This hook provides an abstraction for getting courses that a user is allowed to enroll in.
 *
 * @param {user}
 *    @user the user object that will define which courses are enrolled
 * @returns {mutation,data}
 *    @mutation the mutated hook to be used to retrieve courses.
 *    @data list of enrollable courses as a list of strings
 */

const getCoursesByUser = async (user) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_API}/courses/getEnrollableCourses/${user.userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();
    if (response.status === 200) {
      return data.response;
    } else {
      return false;
    }
  } catch (error) {
    console.error(
      `There was an error getting courses for user: ${user}. Error: ${error}`
    );
  }
  return {};
};

export const useGetCoursesByUser = () => {
  const mutation = useMutation((user) => getCoursesByUser(user), {
    onSuccess: (data) => {},
    onError: (error) => {
      console.error(`Error: ${error}`);
    },
  });

  return mutation;
};
