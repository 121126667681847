import { Grid, List, ListItem, Typography } from "@mui/material";
import React from "react";
import logo from "../../assets/images/logo_transparent.png";

export const DoneStep = ({ accessCode }) => {
  if (!accessCode) {
    accessCode = "AAAAAA";
  }
  return (
    <Grid container spacing={2} alignItems="center">
      {" "}
      {/* Parent Grid container */}
      <Grid item xs={12} sm={6}>
        {" "}
        {/* Grid item for text */}
        <Typography id="welcome-title" variant="h4" color="primary" sx={{ fontWeight: "bold", textAlign: "center" }}>
          Try Out Your Tailored Tutor!
        </Typography>
        <List id="welcome-description">
          <ListItem>
            Enjoy 5 free messages a day with your Tailored Tutor.
          </ListItem>
          <ListItem>
            <Typography variant="h3" style={{ fontWeight: "bold" }}>
              {accessCode}
            </Typography>
          </ListItem>
          <ListItem>
            Unlock unlimited messages by sharing the access code above with up to 3 faculty, TAs, or students!
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12} sm={6}>
        {" "}
        <img
          src={logo}
          alt="Tailored Tutor Logo"
          style={{ maxWidth: "100%", height: "auto" }}
        />
      </Grid>
    </Grid>
  );
};
