import { useMutation } from "react-query";
import { useRef } from "react";

/** Handles enrollment of a user into a course
 *
 * @param {userId, courseId}
 *    @userId identifies the user to enroll
 *    @courseId identifies the course to enroll the user into
 * @returns {mutation, data}
 *    @mutation the mutated hook to be used to retrieve messages that calss the fetch and returns.
 *    @data returns a boolean on whether the response was successful or not
 *
 */

const handleEnrollInCourse = async (userId, courseId) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_API}/users/enrollUser/${userId}/${courseId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();

    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("There was an error enrolling in the course:", error);
  }
  return {};
};

export const useHandleEnrollInCourse = () => {
  const resolveMutation = useRef(null);

  const mutation = useMutation(
    ([userId, courseId]) => handleEnrollInCourse(userId, courseId),
    {
      onSettled: (data, error) => {
        if (resolveMutation.current) {
          if (error) {
            resolveMutation.current.reject({ error });
          } else {
            resolveMutation.current.resolve({ data });
          }
        }
      },
    }
  );

  const handleEnrollInCourseWithPromise = (userId, courseId) => {
    return new Promise((resolve, reject) => {
      resolveMutation.current = { resolve, reject };
      mutation.mutate([userId, courseId]);
    });
  };

  return { ...mutation, handleEnrollInCourse: handleEnrollInCourseWithPromise };
};
